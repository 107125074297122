import React, { Component } from "react"
import axios from "axios"
import endpoints from "../utils/endpoints"
import Popup from "./modal"
import { Col, Row, Button, Form, FormGroup, Label, Input } from "reactstrap"
import {
FaFacebookMessenger,
FaInstagram,
FaTelegramPlane,
FaHeart
} from "react-icons/fa"
import InputRange from 'react-input-range';


export default class ReviewFeedBack extends Component {
    constructor (props, context) {
    super(props, context)
    this.state = {
        name: "",
        model: "",
        area: "",
        service_rating: { min: 2, max: 10 },
        message_to_improve: "",
        thoughts_about_us: "",
        showModal: false,
        valid: false,
        error: false,
    }
}

change = e => {
    let whoIsChecked = Object.assign({}, this.state.whoIsChecked);
    whoIsChecked.allowDestroyAll = e.target.value
    this.setState({ whoIsChecked })
    console.log(whoIsChecked.allowDestroyAll)
}

handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
        [name]: value,
    })
}

handleSubmit = e => {
    this.setState({ loading: true })
    let model = this.state.whoIsChecked.allowDestroyAll;
    // console.log("model is", model)

    let { name, area, service_rating, message_to_improve, thoughts_about_us } = this.state
    // let { name, email, phone, address, area, coupon, message } = this.state
    let data = { name, model, area, service_rating, message_to_improve, thoughts_about_us }
    axios
    .post(endpoints.feedback, JSON.stringify(data))
    .then(data => {
        this.handleSuccess(data)
    })
    .catch(({ error, response }) => {
        this.handleError(error, response)
    })
    e.preventDefault()
}

handleSuccess = data => {
    console.log(data)
    this.setState({
        name: "",
        model: "",
        area: "",
        service_rating: "",
        message_to_improve: "",
        thoughts_about_us: "",
        showModal: true,
        valid: false,
        alert: `Thank You For Your Testimonial and Feedback ${<FaHeart color="crimson"/>}.`,
    })
}

handleError = (error, data) => {
    console.log(data)
    this.setState({
        showModal: true,
        alert: `${data.data.errorMessage}`,
        valid: false,
        loading: false,
    })
}

closeModal = () => {
    this.setState({ showModal: false })
}

onConfirm = () => {
    this.setState({ valid: true })
}


render() {
    // let { service_rating } = this.state
    let { name, service_rating,model, area,  message_to_improve, thoughts_about_us,alert } = this.state
    return (
    <div>
        <div>
        <h3 className="text-center text-dark p-5 h-50 font-weight-bold">
            Give us a  <span className="text-color-primary">Review</span>
        </h3>
        </div>
        <Row className="d-flex justify-content-center">
        <Col md={10}>
            <Form name="contact Form" onSubmit={this.handleSubmit}>
            <Row form>
                <Col sm={3}>
                <p>
                    <strong>Name</strong>
                </p>
                </Col>
                <Col sm={8}>
                    <FormGroup>
                        <Input
                            name="name"
                            type="text"
                            placeholder="Full Name"
                            value={name}
                            onChange={this.handleInputChange}
                            autoComplete="off"
                            required
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row form>
                <Col sm={3}>
                <p>
                    <strong>Rate Us</strong>
                </p>
                </Col>
                <Col md={8}>
                <FormGroup>
                    <Input
                        name="service_rating"
                        type="number"
                        min="1" 
                        max="5"
                        step="0.5"
                        placeholder="Rate Our Service Out of 5"
                        value={service_rating}
                        onChange={this.handleInputChange}
                        autoComplete="off"
                        required
                    />
                </FormGroup>
                </Col>
            </Row>
            <Row form>
                <Col sm={3}>
                <p>
                    <strong>Locality / Area</strong>
                </p>
                </Col>
                <Col md={8}>
                <FormGroup>
                    <Input
                        name="area"
                        type="text"
                        placeholder="Location"
                        value={area}
                        onChange={this.handleInputChange}
                        autoComplete="off"
                        required
                    />
                </FormGroup>
                </Col>
            </Row>
            <Row form>
                <Col sm={3}>
                <p>
                    <strong>Device Type</strong>
                </p>
                </Col>
                <Col md={8}>
                <FormGroup>
                    <Input
                        type="select"
                        name="model"
                        id="exampleSelect"
                        onChange={this.change}
                        placeholder="Device Type"
                        required="required"
                    >
                        <option value="">Select a Device</option>
                        <option>Mobile</option>
                        <option>Tablet</option>
                        <option>Laptop</option>
                        <option>Desktop</option>
                        <option>CCTV Camera</option>
                    </Input>
                </FormGroup>
                </Col>
            </Row>
            <Row form>
                <Col sm={3}>
                <p>
                    <strong>Share Your Experience On Using Our Platform</strong>
                </p>
                </Col>
                <Col md={8}>
                    <FormGroup>
                        <Input
                            name="thoughts_about_us"
                            type="textarea"
                            placeholder="Tell the World about using Recticart's Service"
                            value={thoughts_about_us}
                            onChange={this.handleInputChange}
                            autoComplete="off"
                            required
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row form>
                <Col sm={3}>
                <p>
                    <strong>How can We Improve Ourself?</strong>
                </p>
                </Col>
                <Col md={8}>
                    <FormGroup>
                        <Input
                            name="message_to_improve"
                            type="textarea"
                            placeholder="Some Suggestions/Ideas to Improve Our Service"
                            value={message_to_improve}
                            onChange={this.handleInputChange}
                            autoComplete="off"
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row form className="justify-content-center">
                <Col md={8}>
                    <Button
                        className="text-center py-3 font-weight-bold px-5 bg-color-primary btn-block"
                        name="submit"
                        type="submit"
                        value="Send It"
                    >
                        Send Review
                    </Button>
                </Col>
            </Row>
            <Popup
                visible={this.state.showModal}
                onClickAway={() => this.closeModal()}
            >
                <p>
                <div dangerouslySetInnerHTML={{ __html: `${alert}` }} />
                </p>
            </Popup>
            </Form>
        </Col>
        </Row>
        <Row className="d-flex justify-content-center">
        <Col md={6} className="mt-5">
            <h4 className="text-center font-weight-bold">
            Contact us via{" "}
            <span className="text-color-primary">Social Media</span>
            </h4>
            <div className="d-flex justify-content-center mt-3">
            <Col size={3}>
                <a
                href="https://m.me/recticart"
                rel="noopener nofollow"
                target="_blank"
                >
                <FaFacebookMessenger
                    size={70}
                    color="#552277"
                    className="px-2"
                />
                <p className="text-color-primary">Messenger</p>
                </a>
            </Col>
            <Col size={3}>
                <a
                href="https://www.instagram.com/recticart/"
                rel="noopener nofollow"
                target="_blank"
                >
                <FaInstagram size={70} color="#552277" className="px-2" />
                <p className="text-color-primary">Instagram</p>
                </a>
            </Col>
            <Col size={3}>
                <FaTelegramPlane size={70} color="#552277" className="px-2" />
                <p className="text-color-primary">Telegram</p>
            </Col>
            </div>
        </Col>
        </Row>
    </div>
    )
}
}